import { Box, Typography } from "@mui/material";
import AdSenseFC from "./AdSenseFC";

const AdSensePlug = ({ showLogo = true, mt = 4 }) => {
  return (
    <Box
      alignItems="center"
      flexDirection="column"
      display="flex"
      width={"100%"}
      height={"100%"}
    >
      {showLogo && (
        <Box alignItems="center" flexDirection="column" display="flex">
          <Box
            component="img"
            sx={{
              height: 200,
              width: 200,
            }}
            mr={1}
            src="/logo.png"
            pb={3}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Tripplay
          </Typography>
          <Box
            pt={2}
            sx={{
              fontWeight: "lighter",
              textAlign: "center",
              fontSize: 48,
            }}
          >
            Ściągnij naszą aplikację!
          </Box>
        </Box>
      )}
      <Box
        sx={{
          width: 300,
          height: "100%",
          mt,
          borderStyle: process.env.NODE_ENV === "development" ? "solid" : "",
        }}
      >
        <AdSenseFC slot="3618087247" />
      </Box>
    </Box>
  );
};

export default AdSensePlug;
