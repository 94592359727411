import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { imageUrl } from "../integration/httpService";
import { Box } from "@mui/material";
import { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

// TODO vertical images
interface ImagesCarouselI {
  images?: any[];
  image?: any;
}
const ImagesCarousel = ({ images, image }: ImagesCarouselI) => {
  images = images || (image ? [image] : []);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <Box>
      <Carousel
        showIndicators={images?.length > 1}
        showThumbs={false}
        showStatus={false}
        dynamicHeight={false}
      >
        {images.map((image, index) => (
          <Box
            key={image.id}
            onClick={() => openImageViewer(index)}
            sx={{ height: "100%", maxHeight: { xs: 300, lg: 200 } }}
          >
            {/* // eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              src={imageUrl(image.url)}
              style={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                background: "transparent",
              }}
              alt="Your attraction" // TODO
            />
          </Box>
        ))}
      </Carousel>
      {isViewerOpen && (
        <ImageViewer
          src={images.map((image) => imageUrl(image.url))}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </Box>
  );
};

export default ImagesCarousel;
