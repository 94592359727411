import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Card as MuiCard,
} from "@mui/material";
import { Link } from "react-router-dom";
import { imageUrl } from "../../integration/httpService";
import SubtitleItemCard from "./itemCard/SubtitleItemCard";
import TitleItemCard from "./itemCard/TitleItemCard";

interface ImageCardI {
  image: any;
  linkTo: string;
  width?: number;
  title: string;
  subtitle: string;
  sx?: any;
}

// TODO move to common constants file
const ImageAspectRatio = 9 / 16;

const ImageCard = (props: ImageCardI) => {
  const { image, width, title, linkTo, subtitle, sx } = props;
  return (
    <MuiCard
      sx={{
        width,
        height: "100%",
        ...sx,
      }}
    >
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          textDecoration: "none",
          color: "#111 !important",
        }}
        component={Link}
        to={linkTo}
      >
        {image && (
          <Box
            sx={{
              width: "100%",
              height: 0,
              overflow: "hidden",
              paddingBottom: ImageAspectRatio * 100 + "%",
              position: "relative",
            }}
          >
            <CardMedia
              component="img"
              src={imageUrl(image.url)}
              sx={{
                position: "absolute",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        )}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 1,
            flex: 1,
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            flex="1"
          >
            <TitleItemCard fontWeight="bold">{title}</TitleItemCard>
            <SubtitleItemCard>{subtitle}</SubtitleItemCard>
          </Box>
        </CardContent>
      </CardActionArea>
    </MuiCard>
  );
};

export default ImageCard;
