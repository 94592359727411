import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "leaflet/dist/leaflet.css";
import { useParams } from "react-router-dom";
import ContentAds from "../adsense/ContentAds";
import HeaderImages from "../components/HeaderImages";
import ImagesCarousel from "../components/ImagesCarousel";
import VerticalBanner from "../components/VerticalBanner";
import PageWrapper from "../components/common/PageWrapper";
import ItemCard from "../components/common/itemCard/ItemCard";
import ItemCardSkeleton from "../components/common/itemCard/ItemCardSkeleton";
import { MostPopular } from "../components/mostPopular/MostPopular";
import { useProfileRequest } from "../integration/httpHooks";
import HelmetHeader from "../utilis/HelmetHeader";
import CityHeader from "./components/CityHeader";
import Description from "./components/Description";
import CityMap from "./map/CityMap";

const City = () => {
  const { cityId } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const numberOfPopularElements = isSmallScreen ? 3 : 8;

  const { data = {}, isLoading } = useProfileRequest(cityId as string);

  const { tours = [], attractions = [], news = [], events = [] } = data;
  return (
    <Box display="flex" flex="1">
      <HelmetHeader
        isLoading={isLoading}
        title={data?.name}
        description={data?.description}
      />
      <PageWrapper direction="row">
        <Box sx={{ flex: { xs: 1, md: 0.7 }, minWidth: "1px" }}>
          <CityHeader isLoading={isLoading} name={data?.name} />
          <HeaderImages isLoading={isLoading} images={data?.images || []} />

          <Box sx={{ display: { md: "none" } }}>
            <ImagesCarousel images={data?.images || []} />
          </Box>
          <Description isLoading={isLoading} content={data?.description} />

          <MostPopular
            children={news
              ?.slice(0, numberOfPopularElements)
              ?.map((news: any) => (
                <ItemCard
                  key={news.id}
                  linkTo={`/article?id=${news.id}&cityId=${cityId}`}
                  title={news.name}
                  description={news.description}
                  image={news.images?.[0]}
                />
              ))}
            isCarousel={false}
            title={"city.news.title"}
            toAll={`/articles?cityId=${cityId}&page=1`}
            spaceInHeader={false}
            isLoading={isLoading}
            cardSkeleton={<ItemCardSkeleton />}
            numberOfChildrenSkeleton={3}
          />

          <MostPopular
            mt={2}
            children={attractions
              ?.slice(0, numberOfPopularElements)
              ?.map((attraction: any) => (
                <ItemCard
                  key={attraction.id}
                  linkTo={`/attraction?id=${attraction.id}&cityId=${cityId}`}
                  title={attraction.name}
                  description={attraction.description}
                  image={attraction.mainImage}
                />
              ))}
            isCarousel={false}
            title={"city.attractions.title"}
            toAll={`/attractions?cityId=${cityId}&page=1`}
            spaceInHeader={false}
            justifyContentMd={
              attractions?.length > 2 ? "space-between" : "flex-start"
            }
            isLoading={isLoading}
            cardSkeleton={<ItemCardSkeleton />}
            numberOfChildrenSkeleton={3}
          />
          <ContentAds />
          <MostPopular
            children={events
              ?.slice(0, numberOfPopularElements)
              ?.map((event: any) => (
                <ItemCard
                  key={event.id}
                  linkTo={`/event?id=${event.id}&cityId=${cityId}`}
                  title={event.name}
                  description={event.description}
                  startDate={event.startDate}
                  image={event.mainImage}
                  numberOfLines={3}
                />
              ))}
            mt={2}
            isCarousel={false}
            title={"city.events.title"}
            toAll={`/events?cityId=${cityId}&page=1`}
            spaceInHeader={false}
            justifyContentMd={"space-between"}
            isLoading={isLoading}
            cardSkeleton={<ItemCardSkeleton />}
            numberOfChildrenSkeleton={3}
          />
          <MostPopular
            justifyContentMd={
              tours?.length > 2 ? "space-between" : "flex-start"
            }
            mt={2}
            children={tours
              ?.slice(0, numberOfPopularElements)
              ?.map((tour: any) => (
                <ItemCard
                  key={tour.id}
                  linkTo={`/tour?id=${tour.id}&cityId=${cityId}`}
                  title={tour.name}
                  description={tour.description}
                  image={tour.mainImage}
                />
              ))}
            isCarousel={false}
            title={"city.tours.title"}
            toAll={`/tours?cityId=${cityId}&page=1`}
            spaceInHeader={false}
            isLoading={isLoading}
            cardSkeleton={<ItemCardSkeleton />}
            numberOfChildrenSkeleton={3}
          />
        </Box>

        <VerticalBanner>
          <CityMap cityId={cityId} />
        </VerticalBanner>
      </PageWrapper>
    </Box>
  );
};

export default City;
