import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PageWrapper from "../components/common/PageWrapper";
import {
  GetBuildingCities,
  GetBuildingCitiesQuery,
  GetCities,
  GetCitiesQuery,
} from "../integration/apiRoutes";
import { getMethod } from "../integration/httpService";
import HelmetHeader from "../utilis/HelmetHeader";
import RandomAttractions from "./RandomAttractions";
import Search from "./Search";
import SuggestedAttractions from "./SuggestedAttractions";

import CitiesPresentation from "./CitiesPresentation";

const Dashboard = () => {
  const { t } = useTranslation();

  const { isLoading: isLoadingCities, data: dataCities } = useQuery(
    [GetCitiesQuery],
    () => getMethod(GetCities)
  );
  const { isLoading: isLoadingBuildingCities, data: dataBuildingCities } =
    useQuery([GetBuildingCitiesQuery], () => getMethod(GetBuildingCities));

  const cities = isLoadingCities ? [] : dataCities;
  const buildingCities = isLoadingBuildingCities ? [] : dataBuildingCities;

  const isLoading = isLoadingCities || isLoadingBuildingCities;

  return (
    <Box>
      {/* TODO */}
      <HelmetHeader
        title="Tripplay, zwiedzajmy razem Polskę."
        description="Tripplay to nowoczesny przewodnik. Zwiedzaj z nami polskie miasta!"
      />
      <Search />
      <Container
        disableGutters
        sx={{ paddingLeft: { xs: 2, md: 5 }, paddingRight: { xs: 2, md: 5 } }}
      >
        <PageWrapper>
          {/* TODO tymczasowo  */}
          {/* <CitiesCountUp target={cities?.length + buildingCities?.length} /> */}
          <CitiesPresentation
            cities={cities}
            buildingCities={buildingCities}
            isLoading={isLoading}
          />
        </PageWrapper>
        <SuggestedAttractions />
        <RandomAttractions />
      </Container>
    </Box>
  );
};

export default Dashboard;
