import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import { useTranslation } from "react-i18next";
import FacebookButton from "../components/buttons/FacebookButton";
import InstagramButton from "../components/buttons/InstagramButton";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

// @ts-ignore
const pageSettings: any[] = [
  {
    name: "menu.mainPage",
    path: "/",
  },
  // {
  //   name: "menu.yourCity",
  //   path: "/city",
  // },
];

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (pageSetting: any) => {
    if (pageSetting) {
      navigate(pageSetting.path);
    }
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              height: 40,
              width: 40,
              cursor: "pointer",
            }}
            mr={1}
            src="/logo.png"
            onClick={() => navigate("/")}
            // style={{ curser: "pointer" }}
          />
          <Typography
            variant="h4"
            noWrap
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 600,
              // letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            tripplay
          </Typography>

          <Typography
            variant="h5"
            noWrap
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 600,
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            tripplay
          </Typography>
          {!isEmpty(pageSettings) && (
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pageSettings.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => handleCloseNavMenu(page)}
                  >
                    <Typography textAlign="center"> {t(page.name)}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pageSettings.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleCloseNavMenu(page)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {t(page.name)}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <FacebookButton />
            <InstagramButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
