import { NumberParam, useQueryParam } from "use-query-params";
import AllElementsPage from "../components/allElements/AllElementsPage";
import { useProfileRequest } from "../integration/httpHooks";

const Attractions = () => {
  const [cityId] = useQueryParam("cityId", NumberParam);

  const profileResponse = useProfileRequest(cityId as number);

  return (
    <AllElementsPage
      getLinkTo={(item) => `/attraction?id=${item.id}&cityId=${cityId}`}
      title="attraction.title"
      backendPagination={false}
      isLoading={profileResponse.isLoading}
      backendData={profileResponse.data?.attractions}
      cityId={cityId}
    />
  );
};

export default Attractions;
