import { Box, Pagination } from "@mui/material";
import { useEffect } from "react";
import { NumberParam, useQueryParam } from "use-query-params";
import SuggestedAttractionsGrid from "../../dashboard/SuggestedAttractionsGrid";
import SuggestedCard from "../../dashboard/SuggestedCard";
import VerticalBanner from "../VerticalBanner";
import HeaderPage from "../common/HeaderPage";
import PageWrapper from "../common/PageWrapper";

export const PageSize = 9;

interface AllElementsPageI {
  backendPagination?: boolean;
  backendData: any;
  numberOfPages?: number;
  isLoading?: boolean;
  title: string;
  getLinkTo: (item: any) => string;
  cityId?: number | any;
}

const AllElementsPage = (props: AllElementsPageI) => {
  const {
    backendPagination = false,
    backendData = [],
    numberOfPages,
    isLoading = false,
    title,
    getLinkTo,
    cityId,
  } = props;
  const [page, setPage] = useQueryParam("page", NumberParam);

  const currentPage = page || 1;
  const pages = numberOfPages || Math.ceil(backendData.length / PageSize);
  const data = backendPagination
    ? backendData
    : backendData?.slice((currentPage - 1) * PageSize, currentPage * PageSize);

  useEffect(() => {
    if (!isLoading) {
      if (page && page > pages) {
        setPage(pages);
      } else if ((page && page <= 0) || !page) {
        setPage(1);
      }
    }
  }, [page, pages, setPage, isLoading]);

  return (
    <PageWrapper direction="row">
      <Box sx={{ flex: { xs: 1, md: 0.7 }, gap: 5, minWidth: "1px" }}>
        <HeaderPage
          isLoading={isLoading}
          translationKey={title}
          hideCopyButton
          isBack
          cityId={cityId}
        />
        <SuggestedAttractionsGrid>
          {data?.map((item: any) => {
            return (
              <SuggestedCard
                key={item.id}
                linkTo={getLinkTo(item)}
                image={item.mainImage}
                subtitle={item.address}
                title={item.name}
                description={item.description}
                cityId={item.cityId}
                showButtons={true}
              />
              // <HorizontalCard
              //   action={() => action(item)}
              //   title={item.name}
              //   description={item.description}
              //   subTitle={item.address}
              //   image={item.mainImage}
              // />
            );
          })}
        </SuggestedAttractionsGrid>
        <Box display="flex" justifyContent="center" alignItems="center">
          {!isLoading && (
            <Pagination
              page={page || 0}
              onChange={(e, page) => setPage(page)}
              count={pages}
              color="primary"
            />
          )}
        </Box>
      </Box>
      <VerticalBanner />
      {/* <Box flex="0.3" sx={{ display: { xs: "none", md: "flex" }, gap: 3 }}>
        <AdSensePlug />
      </Box> */}
    </PageWrapper>
  );
};

export default AllElementsPage;
