import LocationCityIcon from "@mui/icons-material/LocationCity";
import {
  Box,
  Button,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { divIcon, icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import CityCard from "../components/cards/CityCard";
import SkeletonCard from "../components/cards/SkeletonCard";
import ImageCard from "../components/common/ImageCard";
import InfinityCarousel from "../components/common/InfinityCarousel";
import { MostPopular } from "../components/mostPopular/MostPopular";
import qrCode from "../images/qrCode.png";
import { imageUrl } from "../integration/httpService";
import { Paths } from "../utilis/Paths";
import MarkerClusterGroup from "react-leaflet-cluster";

const iconSize = 32;

const CitiesPresentation = ({
  cities = [],
  buildingCities = [],
  isLoading,
}: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const { t } = useTranslation();
  const myRef = useRef(null);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const city = cities[currentIndex];

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(5) }}
    >
      <Typography sx={{ textAlign: "center" }} variant="h4">
        Zabieramy Ciebie w magiczną podróż po Polsce!
      </Typography>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          style={{ height: 600, width: "100%" }}
        />
      ) : (
        <Box>
          <Box
            sx={{
              backgroundImage: {
                xs: `linear-gradient(to bottom,rgb(25, 48, 76, 0.1),   rgba(25, 48, 76, 1)),
            url(${imageUrl(city?.mainImage?.url)})`,
                sm: `linear-gradient(to bottom,rgb(25, 48, 76, 0.05) 20%,   rgba(25, 48, 76, 1)),
            url(${imageUrl(city?.mainImage?.url)})`,
              },
              height: isMobile ? 200 : 500,
              backgroundSize: "cover",
              color: "white",
              padding: 4,
              display: "flex",
              borderTopRightRadius: 16,
              borderTopLeftRadius: 16,
              transition: `background-image 0.6s ease-in-out`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "flex-end",
                gap: theme.spacing(3),
              }}
            >
              <Box>
                <Typography variant="h3" mb={1}>
                  {city.name}
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: theme.spacing(3),
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    variant="h5"
                    my="auto"
                    display={{ xs: "none", sm: "block" }}
                  >
                    Zwiedzaj razem z nami!
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                    gap={{ xs: theme.spacing(1), md: theme.spacing(3) }}
                  >
                    <Button
                      variant="outlined"
                      size={isMobile ? "medium" : "large"}
                      style={{
                        backgroundColor: "white",
                        whiteSpace: "nowrap",
                        width: 150,
                      }}
                      onClick={() =>
                        //   @ts-ignore
                        myRef?.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        })
                      }
                    >
                      Sprawdź mapę!
                    </Button>
                    <Button
                      variant="contained"
                      size={isMobile ? "medium" : "large"}
                      style={{
                        width: 150,
                      }}
                      onClick={() => {
                        navigate(`/city/${city.id}`);
                      }}
                    >
                      Zwiedzam!
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            pt={5}
            style={{
              backgroundImage: `linear-gradient(to bottom,rgba(25, 48, 76, 1) 2%, rgba(247, 249, 255, 1))`,
              width: "100%",
              backgroundSize: "cover",
              color: "white",
              paddingBottom: 45,
              marginTop: -1,
              overflow: "hidden",
            }}
          >
            <InfinityCarousel
              slidesToShow={3}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              callback={setCurrentIndex}
              whiteArrows
            >
              {cities?.map((city: any, index: number) => {
                const isActive = index === currentIndex;
                return (
                  <ImageCard
                    key={city.id}
                    linkTo={`/city/${city.id}`}
                    title={city.name}
                    subtitle={city.province}
                    image={city.mainImage}
                    sx={{
                      flex: 1,
                      maxWidth: "80%",
                      position: "relative",
                      transition: "all 0.15s ease-in-out",
                      opacity: isActive ? 1 : 0.75,
                      boxShadow: isActive
                        ? "0px 0px 9px -2px rgba(66, 68, 90, 1)"
                        : "",
                      zIndex: isActive ? 10 : 1,
                      transform: isActive
                        ? "scale3d(1.1, 1.1, 1)"
                        : "scale3d(0.95, 0.95, 1)",
                      "&:hover": {
                        transform: isActive
                          ? "scale3d(1.25, 1.25, 1)"
                          : "scale3d(1, 1, 1)",
                      },
                    }}
                  />
                );
              })}
            </InfinityCarousel>
          </Box>
        </Box>
      )}
      <Box
        style={{
          overflow: "hidden",
        }}
      >
        <MostPopular
          infinity
          showCheckAll={false}
          title={t("dashboard.cities.isBuilding")}
          children={buildingCities?.map((city: any) => {
            return (
              <CityCard
                key={city.id}
                title={city.name}
                subtitle={city.province}
                isBuilding
              />
            );
          })}
          toAll={Paths.cities}
          cardSkeleton={<SkeletonCard />}
          isLoading={isLoading}
          infinityCarouselResponsive={[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 735,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 515,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        />
      </Box>
      <Box>
        <Box
          ref={myRef}
          sx={{
            fontWeight: "lighter",
            textAlign: "center",
            fontSize: 48,
            paddingBottom: theme.spacing(3),
          }}
        >
          Jesteśmy tutaj!
        </Box>
        <Box id="map">
          <MapContainer
            center={[52.0689163366494, 19.47961577361076]}
            style={{ height: 500 }}
            zoom={6}
            bounds={[
              [54.5, 14.07],
              [49.0, 24.08],
            ]}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup
              chunkedLoading
              showCoverageOnHover={false}
              maxClusterRadius={1}
            >
              {[...cities, ...buildingCities].map((city: any) => {
                if (!city.location?.latitude || !city.location?.longitude) {
                  return null;
                }
                const isBuilding = city.isBuilding;
                const Icon = !isBuilding
                  ? icon({
                      iconUrl:
                        (city.mainImage?.url || !isBuilding) &&
                        imageUrl(city.mainImage?.url, "/ICON"),
                      iconSize: [iconSize, iconSize],
                      iconAnchor: [iconSize / 2 + 2, iconSize / 2 + 2],
                      popupAnchor: [0, -(iconSize / 2 + 4)],
                      className: "marker-icon",
                    })
                  : undefined;

                return (
                  <Marker
                    icon={!isBuilding ? Icon : buildingIcon}
                    key={city.id}
                    position={[city.location.latitude, city.location.longitude]}
                    eventHandlers={{
                      mouseover: (event) => event.target.openPopup(),
                    }}
                  >
                    <Popup className="request-popup">
                      <ImageCard
                        key={city.id}
                        linkTo={isBuilding ? "#" : `/city/${city.id}`}
                        width={260}
                        title={city.name}
                        subtitle={city.province}
                        image={!isBuilding && city.mainImage}
                      />
                    </Popup>
                  </Marker>
                );
              })}
            </MarkerClusterGroup>
          </MapContainer>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            fontWeight: "lighter",
            textAlign: "center",
            fontSize: 48,
            paddingBottom: theme.spacing(1),
          }}
        >
          Mamy też aplikację mobilną!
        </Box>
        <Box
          sx={{
            fontWeight: "lighter",
            textAlign: "center",
            fontSize: 24,
            paddingBottom: theme.spacing(3),
          }}
        >
          Jesteśmy dostępni na Twój telefon, ściągnij naszą aplikację i bądź na
          bieżąco z nowościami!
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="a"
            target="blank"
            href="https://apps.apple.com/us/app/tripplay/id1534939032?itsct=apps_box_badge&amp;itscg=30200"
          >
            <Box
              component="img"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pl-pl?size=250x83&amp;releaseDate=1616630400&h=182ee123519e75a1fbb8c1986733367c"
              alt="Download on the App Store"
              sx={{
                width: "180px",
              }}
            />
          </Box>
          <Box
            component="a"
            target="blank"
            href="https://play.google.com/store/apps/details?id=pl.tripplay&hl=pl&gl=PL&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <Box
              component="img"
              alt="pobierz z Google Play"
              sx={{
                width: { xs: "205px", md: "180px" },
              }}
              src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png"
            />
          </Box>
        </Box>
        <Box
          pt={5}
          style={{
            backgroundImage: `url(${qrCode})`,
            height: 220,
            width: 220,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Box>
    </Box>
  );
};

export default CitiesPresentation;

const buildingIcon = divIcon({
  html: renderToString(
    <div
      style={{
        height: iconSize,
        width: iconSize,
        background: "#fff",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: `999 !important`,
        border: "2px solid #19304c",
      }}
    >
      <LocationCityIcon
        style={{ height: iconSize - 7, width: iconSize - 7, color: "#19304c" }}
      />
    </div>
  ),
  iconAnchor: [iconSize / 2 + 2, iconSize / 2 + 2],
  popupAnchor: [0, -(iconSize / 2 + 4)],
});
